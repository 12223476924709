<template>
  <div
    id="app"
    :class="theme">
    <loader />
    <app-header />
    <router-view />
  </div>
</template>

<script>
import postscribe from 'postscribe';
import AppHeader from '@/components/common/app-header/AppHeader.vue';
import Loader from '@/components/common/loader/Loader.vue';

export default {
  name: 'App',
  components: { AppHeader, Loader },
  data() {
    const { theme } = this.$store.state.settings.app;

    return {
      theme: `theme-${theme}`,
      interval: null,
    };
  },
  mounted() {
    // Google Analytics injection via postscribe
    postscribe('#app', `
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-137709926-1"><\/script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-137709926-1');
      <\/script>
    `);
  },
};
</script>

<style lang="scss">
@import './assets/style/normalize';
@import './assets/style/variables';
@import './assets/style/icons';
@import './assets/style/palette';
@import './assets/style/utilities';
@import './assets/style/animations';
@import './assets/style/themes/colored';

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: $font-family-base;
  font-size: $font-size-base;
  background-color: $color-base-back;
  user-select: none;
}

.template-container {
  position: relative;
  float: left;
  width: 100%;
  height: 95vh;
}
</style>
