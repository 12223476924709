import pako from 'pako';

const decompress = (source) => {
  try {
    return JSON.parse(pako.inflate(source, { to: 'string' }));
  } catch (e) {
    console.warn('[decompress] Invalid data received => ', e);
    return {};
  }
};

export { decompress as default };
