const getQueryParam = (key) => {
  const name = key.replace(/\[\]]/g, '\\$&');
  const url = window.location.href;
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return true;

  return window.decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export { getQueryParam as default };
