import { filter, find, map } from 'lodash';

const filterDetailedMatches = matchesDetails => filter(matchesDetails,
  matchDetail => matchDetail.match_display !== null);

const TEMPLATES = {
  standard: 'standard',
  standard_plus: 'standard-plus',
  medium: 'medium',
  stream: 'special',
  special: 'special',
  special_plus: 'special-plus',
};

export default class Selection {
  constructor(options) {
    this.update(options);
  }

  isMatchDisplayable(matchId) {
    return this.matches.indexOf(matchId) > -1;
  }

  getDisplayInfo(matchId) {
    const detail = find(this.details, d => d.idMatch === matchId);
    return detail && detail.display !== 'default' ? detail.display : '';
  }

  update(options) {
    this.template = TEMPLATES[options.template];
    this.matches = map(options.matches, matchId => parseInt(matchId, 10));
    this.details = map(filterDetailedMatches(options.matchesDetails), (matchDetail) => {
      const md = {
        idMatch: matchDetail.id_match,
        display: matchDetail.match_display,
      };
      return md;
    });
  }
}
