import { merge } from 'lodash';
import axios from 'axios';

import SystemsSDK from '@/services/systemsSDK';
import config from './config';

const VISUALS_ID = '9b4e04738493129a956e5da219fb3eda';
const VISUALS_LIVE_BETTING_LION_ID = '0b094ccea995c785d9caa80c9ba6ced0';

const getUrl = (projectId, language, companyUuid) => {
  const languageMapper = {
    sr_latn: 'sr',
    sr_cyrl: 'sr',
    'sr-latn': 'sr',
    'sr-cyrl': 'sr',
  };

  const formattedLanguage = languageMapper[language.toLowerCase()] || language;
  const projectsLocale = `/translations/${projectId}/locales/${formattedLanguage}`;
  const tenantLocale = companyUuid ? `${projectsLocale}/tenants/${companyUuid}` : projectsLocale;

  return `${tenantLocale}?includeLocaleFallback=true`;
};

export default function getTranslations(language) {
  const { settings } = new SystemsSDK();

  const http = axios.create({
    baseURL: config.API.tc[settings.app.env],
    timeout: 10000,
  });
  const visualsUrl = getUrl(VISUALS_ID, language, settings.company.companyUuid);

  const visualsLiveBettingUrl = getUrl(
    VISUALS_LIVE_BETTING_LION_ID,
    language,
    settings.company.companyUuid,
  );

  return axios
    .all([http.get(visualsUrl), http.get(visualsLiveBettingUrl)])
    .then(response => merge({}, response[0].data, response[1].data));
}
