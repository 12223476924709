<template>
  <div
    class="logo"
    :style="`background-image: url(${logoSource.cms})`"
  />
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      logoSource: {
        cms: this.$store.state.settings.app.assets.cms,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/variables";

.logo {
  width: $header-logo-width;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
</style>
