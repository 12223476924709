import { each, find } from 'lodash';
import Vue from 'vue';
import Sport from './sport';
import Category from './category';
import Tournament from './tournament';
import Bet from './bet';
import Match from './match';
import Selection from './selection';
import TemplateGrid from './template-grid';

export default class Live {
  constructor(source) {
    this.bets = {};
    this.updateState(source);
  }

  updateState(source) {
    this.sports = {};
    this.categories = {};
    this.tournaments = {};
    this.matches = [];
    this.matchSelection = {};
    this.templateGrid = {};
    this.matchSelection = {};
    this.templateGrid = {};

    this.addMatchSelection(source);
    this.addTemplateGrid(source);

    // Parse sports
    each(source.sports, (sportSource) => {
      this.addSport(sportSource);
    });

    // Parse categories
    each(source.categories, (categorySource) => {
      this.addCategory(categorySource);
    });

    // Parse tournaments
    each(source.tournaments, (tournamentSource) => {
      this.addTournament(tournamentSource);
    });

    // Parse matches
    each(source.matches, (matchSource) => {
      const match = this.addMatch(matchSource);
      this.matches.push(match);
      // this.addMatch(matchSource);
    });

    // Update bets only if they are not cached
    if (!source.useCachedBets) {
      // Parse bets
      each(source.bets, (betsSource, idSport) => {
        this.bets[idSport] = {};
        each(betsSource, (betSource) => {
          Vue.set(this.bets[idSport], betSource.idBet, new Bet(betSource));
        });
      });
    }
  }

  setMatchesState() {
    each(this.sports, (sport) => {
      sport.resetMatchesCount();
    });

    each(this.matches, (match) => {
      if (this.matchSelection.isMatchDisplayable(match.id)) {
        match.updateDisplayableState(true);
        match.setTemplateInfo(this.matchSelection.getDisplayInfo(match.id));
        this.sports[match.idSport].updateMatchesCount(1);
      } else {
        match.updateDisplayableState(false);
      }
    });
  }

  addMatchSelection(source) {
    Vue.set(this, 'matchSelection', new Selection(source.matchSelection));
  }

  addTemplateGrid(source) {
    Vue.set(this, 'templateGrid', Object.prototype.hasOwnProperty.call(source, 'templateGrid')
      ? new TemplateGrid(source.templateGrid) : {});
  }

  addSport(source) {
    Vue.set(this.sports, source.idSport, new Sport(source));
  }

  addCategory(source) {
    Vue.set(this.categories, source.idCategory, new Category(source));
  }

  addTournament(source) {
    Vue.set(this.tournaments, source.idTournament, new Tournament(source));
  }

  addMatch(source) {
    Vue.$log.debug('[addMatches]', source);
    const match = new Match(source);
    const category = this.categories[match.idCategory];
    const tournament = this.tournaments[match.idTournament];

    match.updatePositionCategory(category.position);
    match.updatePositionTournament(tournament.position);

    if (this.matchSelection.isMatchDisplayable(match.id)) {
      match.updateDisplayableState(true);
      match.setTemplateInfo(this.matchSelection.getDisplayInfo(match.id));
      this.sports[match.idSport].updateMatchesCount(1);
    }
    return match;

    // Vue.set(this.matches, source.idMatch, match);
  }

  removeCategory(id) {
    Vue.$log.debug('[removeCategory]', this.categories[id]);
    Vue.delete(this.categories, id);
  }

  removeTournament(id) {
    Vue.$log.debug('[removeTournament]', this.tournaments[id]);
    Vue.delete(this.tournaments, id);
  }

  removeMatch(id) {
    const match = find(this.matches, { id: Number(id) });
    if (match) {
      Vue.$log.debug('[removeMatches]', match);
      if (this.matchSelection.isMatchDisplayable(match.id)) {
        this.sports[match.idSport].updateMatchesCount(-1);
      }
    }
  }
}
