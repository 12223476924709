import {
  filter,
  sortBy,
  take,
  map,
  orderBy,
  find,
} from 'lodash';

export default {
  socketUrl: (state) => {
    const { app, company } = state.settings;
    const encoding = app.socketCompression ? 'deflate' : 'plaintext';

    const base = state.config.API.socket[app.env];
    return `${base}?product=live&protocol=sio1&contentEncoding=${encoding}&cpv=${company.cpv}`;
  },
  getActiveMatchBets: state => (idMatch, count) => {
    // Filter also bets with 4+ outcomes
    // Method used on medium, special and special-plus
    const match = find(state.live.matches, { id: Number(idMatch) });
    const bets = filter(match.bets,
      mb => mb.active && mb.mostBalanced && mb.outcomes.length < 4);

    const sorted = sortBy(bets, 'position');
    return count ? take(sorted, count) : sorted;
  },
  activeSports: (state) => {
    const sports = filter(state.live.sports,
      sport => sport.active && sport.matchesCount);

    return sortBy(sports, 'position');
  },
  getSportBet: state => (idBet, idSport) => {
    if (!idBet) return {};
    const bet = state.live.bets[idSport][idBet];
    if (!bet.active) return false;
    return bet;
  },
  getActiveSportBets: state => (idSport, count) => {
    const bets = filter(state.live.bets[idSport], (bet) => {
      if (!bet.active) return false;
      return bet;
    });

    const sorted = sortBy(bets, 'position');
    return count ? take(sorted, count) : sorted;
  },
  getActiveSportBetsFromSections: (state, getters) => (betSection) => {
    const { idSport } = betSection;
    const sections = map(sortBy(betSection.sections, 'position'), (section) => {
      const bets = map(section.bets, bet => getters.getSportBet(bet.id, idSport));

      return {
        name: section.name,
        position: section.position,
        bets,
      };
    });

    return sections;
  },
  getSportMatchIds: state => (idSport) => {
    const matches = filter(state.live.matches,
      match => match.active && match.displayable && match.idSport === idSport);
    const ordered = orderBy(matches, ['position', 'positionCategory', 'positionTournament', 'startAt'], ['asc', 'asc', 'asc', 'asc']);
    const mapped = map(ordered, match => match.id);
    return mapped;
  },
  getSportMatches: state => (idSport) => {
    const matches = filter(state.live.matches,
      match => match.active && match.displayable && match.idSport === idSport);
    const ordered = orderBy(matches, ['position', 'positionCategory', 'positionTournament', 'startAt'], ['asc', 'asc', 'asc', 'asc']);
    return ordered;
  },
  getSportMatchBets: (state, getters) => (idMatch) => {
    const idSport = getters.getSportId(idMatch);

    const sportBets = state.live.sports[idSport].bets;
    const { bets: matchBets } = getters.getMatch(idMatch);

    const parsedMatchBets = map(sportBets, (sportBet) => {
      const matchBet = find(matchBets,
        mb => mb.idBet === sportBet.id && mb.active && mb.mostBalanced);

      return matchBet || {};
    });

    return parsedMatchBets;
  },
  getSportId: (state, getters) => idMatch => getters.getMatch(idMatch).idSport,
  getSport: (state, getters) => idMatch => state.live.sports[getters.getSportId(idMatch)],
  getMatch: state => idMatch => find(state.live.matches, { id: idMatch }),
  getMatchStatus: (state, getters) => idMatch => getters.getMatch(idMatch).status,
  getMatchStatusPeriod: (state, getters) => idMatch => getters.getMatch(idMatch).status.period,
  getMatchTeams: (state, getters) => idMatch => getters.getMatch(idMatch).teams,
  getMatchBettingStatus: (state, getters) => idMatch => getters.getMatch(idMatch).bettingStatus,
  getMatchIdSport: (state, getters) => idMatch => getters.getMatch(idMatch).idSport,
  getMatchIdDisplay: (state, getters) => idMatch => getters.getMatch(idMatch).idDisplay,
  getMatchSections: state => key => state.live.templateGrid.matchSections[key],
  getMatchTemplateInfo: (state, getters) => idMatch => getters.getMatch(idMatch).templateInfo,
  templateGrid: state => state.live.templateGrid,
  getScoutMatches: state => (idSport) => {
    const matches = filter(state.live.matches,
      m => m.idSport === idSport && m.active && m.displayable);
    const sorted = orderBy(matches, ['position', 'positionCategory', 'positionTournament', 'startAt'], ['asc', 'asc', 'asc', 'asc']);

    return sorted;
  },
  getStreamMatches: state => (idSport) => {
    const matches = filter(state.live.matches,
      m => m.idSport === idSport && m.active && m.displayable && m.hasStream);
    const sorted = orderBy(matches, ['position', 'positionCategory', 'positionTournament', 'startAt'], ['asc', 'asc', 'asc', 'asc']);

    return sorted;
  },
  getScoutMatchIds: (state, getters) => (idSport) => {
    const matches = getters.getScoutMatches(idSport);
    return map(matches, match => match.id);
  },
  getStreamMatchIds: (state, getters) => (idSport) => {
    const matches = getters.getStreamMatches(idSport);
    return map(matches, match => match.id);
  },
  getTournamentName: state => idTournament => state.live.tournaments[idTournament]
    && state.live.tournaments[idTournament].nameShort,
  getTournamentId: (state, getters) => idMatch => getters.getMatch(idMatch).idTournament,
  getScoutEvents: (state, getters) => idMatch => getters.getMatch(idMatch).scoutEvents,
  visualizationDisabled: state => idMatch => filter(state.live.matchSelection.details, match => match.idMatch === idMatch && match.display === 'MARKETS').length > 0,
  scoutDisplaySelected: state => idMatch => filter(state.live.matchSelection.details, match => match.idMatch === idMatch && match.display === 'SCOUT').length > 0,
  specialMarkets: state => state.specialMarkets,
};
