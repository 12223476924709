import { replace, isEmpty } from 'lodash';

export default class MatchStatus {
  constructor(options) {
    this.updateFromCurrentMatches(options);
  }

  updateFromCurrentMatches(options) {
    this.active = !!options.active;
    this.name = options.betstatus;
    this.time = options.matchtime || false;
    this.result = options.score;
    this.resultPeriods = options.setScores;
    this.resultDetails = options.setScoreDetails || [];
    this.setPeriodStatus(options);
    this.setServer(options);
    this.setCards(options);
    this.setGameScore(options);
    this.setDismissals(options);
  }

  setPeriodStatus(options) {
    /* eslint-disable no-param-reassign */
    if (isEmpty(options.statusDetails)) options.statusDetails = {};
    this.period = options.statusDetails.status;
    this.periodShort = options.statusDetails.translation;
    this.periodTime = (this.time && this.time > 0) ? `${this.time}'` : this.periodShort;

    if (options.overs) {
      this.overs = options.overs;
    }
  }

  setServer(options) {
    if (Object.prototype.hasOwnProperty.call(options, 'server')) {
      this.server = options.server;
    }
  }

  setCards(options) {
    if (Object.prototype.hasOwnProperty.call(options, 'redCardsTotal1')) {
      this.redCards = [options.redCardsTotal1, options.redCardsTotal2];
    }
  }

  setGameScore(options) {
    if (Object.prototype.hasOwnProperty.call(options, 'gameScore')) {
      this.gameScore = replace(options.gameScore, '50', 'A');
    }
  }

  setDismissals(options) {
    if (Object.prototype.hasOwnProperty.call(options, 'htDismissals')) {
      this.dismissals = [options.htDismissals, options.atDismissals];
    }
  }
}
