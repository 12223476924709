import Utils from '../services/utils';

export default class Tournament {
  constructor(options) {
    this.id = parseInt(options.idTournament, 10);
    this.active = !!options.tournamentActive;
    this.name = options.tournamentName;
    this.nameShort = options.tournamentShortName;
    this.prefix = options.tournamentPrefix;
    this.position = Utils.setPosition(options.tournamentPosition);
  }

  update(options) {
    this.active = !!options.tournamentActive;
    this.name = options.tournamentName;
    this.nameShort = options.tournamentShortName;
    this.prefix = options.tournamentPrefix;
    this.position = Utils.setPosition(options.tournamentPosition);
  }
}
