import { each } from 'lodash';
import store from '@/store';

window.addEventListener('message', (message) => {
  const { type } = message.data;
  switch (type) {
    case '7scout-matchState':
    case '7scout-matchMessage': {
      const { data } = message.data;
      each(data.events, (event) => {
        store.dispatch('addMatchScoutEvent', {
          idMatch: +data.match.idMatch,
          event,
        });
      });
      break;
    }
    default:
  }
});
