import VueLogger from 'vuejs-logger';
import Vue from 'vue';

import SystemsSDK from './systemsSDK';

export default function setupLogger() {
  const { app } = new SystemsSDK().settings;

  Vue.use(VueLogger, {
    isEnabled: app.debug,
    logLevel: 'debug',
    separator: '=>',
    showLogLevel: false,
    showMethodName: false,
  });
}
