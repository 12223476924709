export default class MatchOutcome {
  constructor(options) {
    this.active = !!options.mboActive;
    this.id = options.idMbo;
    this.idBo = options.idBo;
    this.value = options.mboOddValue;
    this.type = options.mboType;
    this.position = options.mboPosition;
  }

  updateFromSetOdds(options) {
    this.active = !!options.mboActive;
    this.value = options.mboOddValue;
    this.type = options.mboType;
    this.position = options.mboPosition;
  }
}
