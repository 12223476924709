import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// Use async route components needed for code splitting
export default new VueRouter({
  routes: [
    {
      path: '*',
      redirect: '/standard',
    },
    {
      path: '/standard',
      name: 'Standard',
      component: () => import(/* webpackChunkName: "standard" */ '@/components/templates/standard/Standard.vue'),
    },
    {
      path: '/standard-plus',
      name: 'StandardPlus',
      component: () => import(/* webpackChunkName: "standard-plus" */ '@/components/templates/standard-plus/StandardPlus.vue'),
    },
    {
      path: '/medium',
      name: 'Medium',
      component: () => import(/* webpackChunkName: "medium" */ '@/components/templates/medium/Medium.vue'),
    },
    {
      path: '/special',
      name: 'Special',
      component: () => import(/* webpackChunkName: "special" */ '@/components/templates/special/Special.vue'),
    },
    {
      path: '/special-plus',
      name: 'SpecialPlus',
      component: () => import(/* webpackChunkName: "special-plus" */ '@/components/templates/special-plus/SpecialPlus.vue'),
    },
  ],
});
