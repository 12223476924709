<template>
  <span class="clock flex-center">
    <i class="n-i n-i-clock" />
    <span>{{ hours }}:{{ minutes }}</span>
  </span>
</template>

<script>
function padZero(num) {
  return `${num < 10 ? '0' : ''}${num}`;
}

function getHour() {
  return padZero(new Date().getHours());
}

function getMinutes() {
  return padZero(new Date().getMinutes());
}

export default {
  name: 'Clock',
  data() {
    return {
      hours: getHour(),
      minutes: getMinutes(),
    };
  },
  created() {
    // Since seconds are not displayed, we can refresh clock every 5s
    setInterval(() => {
      this.hours = getHour();
      this.minutes = getMinutes();
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/variables";

.clock {
  font-family: $font-family-base;
  color: $clock-color;
  font-size: 0.875rem;

  i {
    padding-right: 10px;
    font-size: 0.8125rem;
  }
}
</style>
