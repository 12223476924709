<template>
  <div class="row main-header flex-center text-upper">
    <logo v-if="hasLogoSource" />
    <span>{{ 'live_v2.live_betting_header' | translate }}</span>
    <clock />
  </div>
</template>

<script>
import Logo from './Logo.vue';
import Clock from './Clock.vue';

export default {
  name: 'AppHeader',
  components: { Logo, Clock },
  data() {
    return {
      hasLogoSource: this.$store.state.settings.app.assets.cms.length > 0,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/variables";

.main-header {
  color: $color-text-primary;
  background-color: $header-color-back;
  font-weight: $font-weight-bold;
  font-size: 0.8125rem;
  font-family: $font-family-header;
}

.logo {
  position: absolute;
  left: 20px;
  top: 0;
}

.clock {
  position: absolute;
  right: 20px;
  top: 0;
}
</style>
