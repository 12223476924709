export default class Outcome {
  constructor(options) {
    this.id = options.idBetOutcome;
    this.active = !!options.betOutcomeActive;
    this.name = options.betOutcomeName;
    this.nameShort = options.betOutcomeShortName || this.name;
    this.shortcut = options.betOutcomeShortcut;
    this.position = options.betOutcomePosition;
  }

  updateFromUpdateBet(options) {
    this.active = !!options.betOutcomeActive;
    this.name = options.betOutcomeName;
    this.nameShort = options.betOutcomeShortName || this.name;
    this.shortcut = options.betOutcomeShortcut;
    this.position = options.betOutcomePosition;
  }
}
