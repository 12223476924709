import {
  each,
  find,
  findIndex,
  clone,
} from 'lodash';
import Vue from 'vue';
import socket from '@/services/socket';
import router from '@/router';
import Live from '@/model/live'; // Live state

export default {
  setTranslations(state, translations) {
    state.translations = Object.freeze(translations);
  },
  socketInstantiate(state, store) {
    socket.instance(store);
    socket.bindWindowListeners();
  },
  socketConnected(state) {
    console.log('Connected!');
    state.connected = true;
  },
  socketDisconnected(state) {
    console.log('Disconnected!');
    clearTimeout(state.serverServiceAlive);
    clearInterval(state.clientServiceAlive);
    state.connected = false;
  },
  subscribe() {
    console.log('Subscribing...');
    socket.emit({ event: 'subscribe' });
  },
  clientServiceAlive(state) {
    clearInterval(state.clientServiceAlive);
    const clientServiceAlive = setInterval(() => {
      socket.emit({
        event: 'message',
        options: {
          type: 'serviceAlive',
        },
      });
    }, 10000);

    Vue.set(state, 'clientServiceAlive', clientServiceAlive);
  },
  serverServiceAlive(state) {
    // Reconnect if backend service has issues
    clearTimeout(state.serverServiceAlive);
    const serverServiceAlive = setTimeout(() => {
      console.warn('Server service alive issue => ', new Date());
      socket.disconnect();
      setTimeout(() => {
        socket.connect();
      }, 1000);
    }, 30000);

    Vue.set(state, 'serverServiceAlive', serverServiceAlive);
  },
  updateRoute(state) {
    const route = state.live.matchSelection.template;
    if (state.settings.app.template !== route) {
      Vue.set(state.settings, 'template', route);
      router.push(`/${route}`).catch(() => {/* Do nothing */});
    }
  },
  // Handle socket messages
  socketState(state, message) {
    Vue.$log.debug('Raw state', message);
    Vue.set(state, 'betsCacheKey', message.betsCacheKey);

    // Initialize live state only on first backend state
    if (Object.keys(state.live.bets).length === 0) {
      state.live = new Live(message);
    } else {
      state.live.updateState(message);
    }

    Vue.$log.debug('Parsed state', state.live);
  },
  socketMatchSelection(state, message) {
    state.live.matchSelection.update(message);
    state.live.setMatchesState(state.live);
  },
  socketCurrentMatches(state, message) {
    // Update match status
    each(message, (statusSource) => {
      // const match = getters.getMatch(statusSource.idMatch);
      const match = find(state.live.matches, { id: Number(statusSource.idMatch) });
      if (match) {
        const matchIndex = findIndex(state.live.matches, { id: Number(statusSource.idMatch) });
        match.status.updateFromCurrentMatches(statusSource, match.idSport);
        state.live.matches.splice(matchIndex, 1, match);
        // Vue.set(state.live.matches, statusSource.idMatch, match);
      }
    });
  },
  socketMatchBettingStatus(state, message) {
    const match = find(state.live.matches, { id: Number(message.idMatch) });
    if (match) {
      const matchIndex = findIndex(state.live.matches, { id: Number(message.idMatch) });
      match.updateBettingStatus(message);
      state.live.matches.splice(matchIndex, 1, match);
    }
  },
  socketAddMatches(state, message) {
    each(message.categories, (categorySource) => {
      state.live.addCategory(categorySource);
    });
    each(message.tournaments, (tournamentSource) => {
      state.live.addTournament(tournamentSource);
    });
    each(message.matches, (matchSource) => {
      const match = state.live.addMatch(matchSource);
      const matchIndex = findIndex(state.live.matches, { id: Number(matchSource.idMatch) });
      if (matchIndex !== -1) {
        state.live.matches.splice(matchIndex, 1, match);
      } else {
        const matches = clone(state.live.matches);
        matches.push(match);
        Vue.set(state.live, 'matches', matches);
        // state.live.matches.push(match);
      }
    });
  },
  socketRemoveMatches(state, message) {
    const matchIndex = findIndex(state.live.matches, { id: Number(message.idMatch) });
    const match = find(state.live.matches, { id: Number(message.idMatch) });
    if (matchIndex !== -1) {
      if (state.live.matchSelection.isMatchDisplayable(Number(message.idMatch))) {
        state.live.sports[match.idSport].updateMatchesCount(-1);
      }
      state.live.matches.splice(matchIndex, 1);
    }
  },
  socketRemoveTournament(state, message) {
    state.live.removeTournament(message.idTournament);
  },
  socketRemoveCategory(state, message) {
    state.live.removeCategory(message.idCategory);
  },
  socketSetOdds(state, message) {
    const match = find(state.live.matches, { id: Number(message.idMatch) });
    if (match) {
      const matchIndex = findIndex(state.live.matches, { id: Number(message.idMatch) });
      match.updateFromSetOdds(message, match);
      state.live.matches.splice(matchIndex, 1, match);
    }
  },
  socketUpdateBet(state, message) {
    Vue.$log.debug('[updateBet]', message);
    Vue.set(state, 'betsCacheKey', message.betsCacheKey);
    const bet = state.live.bets[message.idSport][message.idBet];
    if (bet) bet.updateFromUpdateBet(message);
  },
  socketUpdateSport(state, message) {
    Vue.$log.debug('[updateSport]', message);
    const sport = state.live.sports[message.idSport];
    if (sport) sport.updateFromUpdateSport(message);
  },
  socketUpdateTeam(state, message) {
    const idTeam = parseInt(message.idTeam, 10);
    find(state.live.matches, (match) => {
      const team = match.getTeam(idTeam);
      if (team) {
        team.updateFromUpdateTeam(message);
        return true;
      }
      return false;
    });
  },
  socketUpdateTournament(state, message) {
    Vue.$log.debug('[updateTournament]', message);
    const idTournament = parseInt(message.idTournament, 10);
    const tournament = state.live.tournaments[idTournament];

    if (tournament) {
      tournament.update(message);
      // Update team prefix
      each(state.live.matches, (match) => {
        if (match.idTournament !== idTournament) return;

        // Update position
        match.updatePositionTournament(tournament.position);
        each(match.teams, (team) => {
          team.updatePrefix(message);
        });
      });
    }
  },
  socketUpdateCategory(state, message) {
    Vue.$log.debug('[updateCategory]', message);
    const idCategory = parseInt(message.idCategory, 10);
    const category = state.live.categories[idCategory];

    if (category) {
      category.update(message);

      // Update position
      each(state.live.matches, (match) => {
        if (match.idCategory !== idCategory) return;
        match.updatePositionCategory(category.position);
      });
    }
  },
  socketUpdateMatch(state, message) {
    Vue.$log.debug('[updateMatch]', message);
    const match = find(state.live.matches, { id: Number(message.idMatch) });
    if (match) {
      const matchIndex = findIndex(state.live.matches, { id: Number(message.idMatch) });
      match.update(message);
      state.live.matches.splice(matchIndex, 1, match);
    }
  },
  updateMatchMediaState(state, message) {
    const match = find(state.live.matches, { id: Number(message.idMatch) });
    if (match) {
      const matchIndex = findIndex(state.live.matches, { id: Number(message.idMatch) });
      match.updateMatchMediaState(message);
      state.live.matches.splice(matchIndex, 1, match);
    }
  },
  addMatchScoutEvent(state, message) {
    const match = find(state.live.matches, { id: Number(message.idMatch) });
    if (match) {
      const matchIndex = findIndex(state.live.matches, { id: Number(message.idMatch) });
      match.addScoutEvent(message.event);
      state.live.matches.splice(matchIndex, 1, match);
    }
  },
  setSportBets(state, message) {
    const sport = state.live.sports[message.idSport];
    if (sport) Vue.set(sport, 'bets', message.bets);
  },
  setSportMatches(state, message) {
    const sport = state.live.sports[message.idSport];
    if (sport) Vue.set(sport, 'matchIds', message.matchIds);
  },
  setSettings(state, data) {
    Vue.set(state, 'settings', data);
  },
};
