import Vue from 'vue';

import SystemsSDK from '@/services/systemsSDK';

import setupLogger from '@/services/logger';
import '@/services/messageListener';
import filters from '@/filters';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(filters);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  async beforeCreate() {
    const data = await new SystemsSDK().init();
    setupLogger();

    this.$store.dispatch('setSettings', data);
    this.$store.dispatch('connect', store);
    this.$store.dispatch('getTranslations', data.app.language);

    this.$mount('#app');
  },
  render: h => h(App),
});
