import { each } from 'lodash';

export default class TemplateGrid {
  constructor(options) {
    this.betsSections = {};
    each(options.betsSections, (betSection) => {
      this.betsSections[betSection.id_sport] = {
        idSport: betSection.id_sport,
        sections: betSection.sections,
      };
    });

    this.matchSections = options.matchSections;
  }
}
