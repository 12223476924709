import { each } from 'lodash';
import Outcome from './outcome';

export default class Bet {
  constructor(options) {
    this.id = options.idBet;
    this.idDisplay = options.betDisplayId;
    this.idSport = parseInt(options.idSport, 10);
    this.active = !!options.betActive;
    this.name = options.betName;
    this.nameShort = options.betShortName;
    this.position = options.betPosition;
    this.specialValueDisplayRules = options.specialValueDisplayRules || false;

    this.outcomes = {};
    each(options.betOutcomes, (outcomeSource) => {
      this.outcomes[outcomeSource.idBetOutcome] = new Outcome(outcomeSource);
    });
  }

  updateFromUpdateBet(options) {
    this.idDisplay = options.betDisplayId;
    this.active = !!options.betActive;
    this.name = options.betName;
    this.nameShort = options.betShortName;
    this.position = options.betPosition;
    each(options.betOutcomes, (boSource) => {
      const bo = this.outcomes[boSource.idBetOutcome];
      if (bo) bo.updateFromUpdateBet(boSource);
    });
  }
}
