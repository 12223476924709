export default class MatchTeam {
  constructor(options) {
    this.id = parseInt(options.id, 10);
    this.prefix = options.tournamentPrefix;
    this.name = options.name;
    this.nameShort = options.nameShort || options.name;
    this.setPrefix();
  }

  setPrefix() {
    this.name = this.prefix.concat(this.name);
    this.nameShort = this.prefix.concat(this.nameShort);
  }

  updatePrefix(options) {
    const prefix = options.tournamentPrefix;
    this.name = this.name.replace(this.prefix, prefix);
    this.nameShort = this.nameShort.replace(this.prefix, prefix);
    this.prefix = prefix;
  }

  updateFromUpdateTeam(options) {
    this.name = options.teamName;
    this.nameShort = options.teamShortName || options.teamName;
    this.setPrefix();
  }
}
