import getTranslations from '@/services/translations';
import decompress from '@/services/decompress';

export default {
  getTranslations({ commit }, language) {
    getTranslations(language)
      .then((response) => {
        commit('setTranslations', response);
      })
      .catch((err) => {
        console.warn('Error getting translations: ', err);
      });
  },
  connect({ commit }, store) {
    commit('socketInstantiate', store);
  },
  socket_connect({ commit }) {
    commit('socketConnected');
    commit('subscribe');
    commit('clientServiceAlive');
  },
  socket_disconnect({ commit }) {
    commit('socketDisconnected');
  },
  socket_message({ commit }, message) {
    const { meta } = message;
    const data = (meta && meta.contentEncoding && meta.contentEncoding.toUpperCase() === 'DEFLATE')
      ? decompress(message.data) : message.data;

    switch (message.type) {
      case 'state': {
        commit('socketState', data);
        commit('serverServiceAlive');
        commit('updateRoute');
        break;
      }
      case 'serviceAlive':
        commit('serverServiceAlive');
        break;
      case 'matchSelection': {
        commit('socketMatchSelection', data);
        commit('updateRoute');
        break;
      }
      case 'currentMatches':
        commit('socketCurrentMatches', data);
        break;
      case 'matchBettingStatus':
        commit('socketMatchBettingStatus', data);
        break;
      case 'addMatches':
        commit('socketAddMatches', data);
        break;
      case 'removeMatches':
        commit('socketRemoveMatches', data);
        break;
      case 'removeTournament':
        commit('socketRemoveTournament', data);
        break;
      case 'removeCategory':
        commit('socketRemoveCategory', data);
        break;
      case 'setOdds':
        commit('socketSetOdds', data);
        break;
      case 'updateBet':
        commit('socketUpdateBet', data);
        break;
      case 'updateSport':
        commit('socketUpdateSport', data);
        break;
      case 'updateTeam':
        commit('socketUpdateTeam', data);
        break;
      case 'updateTournament':
        commit('socketUpdateTournament', data);
        break;
      case 'updateCategory':
        commit('socketUpdateCategory', data);
        break;
      case 'updateMatch':
        commit('socketUpdateMatch', data);
        break;
      default:
    }
  },
  updateMatchMediaState({ commit }, data) {
    commit('updateMatchMediaState', data);
  },
  addMatchScoutEvent({ commit }, data) {
    commit('addMatchScoutEvent', data);
  },
  setSportBets({ commit }, data) {
    commit('setSportBets', data);
  },
  setSportMatches({ commit }, data) {
    commit('setSportMatches', data);
  },
  setSettings({ commit }, data) {
    commit('setSettings', data);
  },
};
