import socketio from 'socket.io-client';
import SystemsSDK from '@/services/systemsSDK';

let sio;
export default {
  instance(store) {
    if (!sio) {
      const url = store.getters.socketUrl;
      const socketOptions = {
        forceNew: true,
        transports: ['websocket'],
      };

      sio = socketio(url, socketOptions);
      sio.on('connect', () => store.dispatch('socket_connect'));
      sio.on('disconnect', () => store.dispatch('socket_disconnect'));
      sio.on('message', message => store.dispatch('socket_message', message));
    }

    return sio;
  },
  connect() {
    if (sio) sio.connect();
  },
  disconnect() {
    if (sio) sio.disconnect();
  },
  emit({
    event,
    options,
  }) {
    const { app, company } = new SystemsSDK().settings;

    switch (event) {
      case 'subscribe':
        sio.emit(event, {
          language: app.language,
          deliveryPlatform: 'RetailVisualization',
          layoutType: app.layout.type,
          layoutId: app.layout.id,
          subscribeOptions: {
            fullBetMeta: true,
            excludeBetGroups: true,
            betsCacheKey: '',
          },
        });
        break;
      case 'message':
        sio.emit(event, {
          type: options.type,
          channel: company.cpv,
          data: options.data,
        });
        break;
      default:
    }
  },
  bindWindowListeners() {
    window.addEventListener('offline', () => {
      this.disconnect();
    });

    window.addEventListener('online', () => {
      // Add some timeout because of network resolve
      setTimeout(() => {
        this.connect();
      }, 500);
    });
  },
};
