import config from '@/services/config';

export default {
  settings: {},
  config: Object.freeze(config),
  betsCacheKey: '',
  connected: false,
  clientServiceAlive: null,
  serverServiceAlive: null,
  translations: {},
  matches: [],
  live: {
    sports: {},
    categories: {},
    tournaments: {},
    bets: {},
    matches: {},
    matchSelection: {},
  },
  specialMarkets: [ // -> markets whose [Y] special value is displayed between the outcomes
    '303/-1/2', // Basketball
    '236/-1/2', // Basketball
    '7/1785/2', // Basketball
    '8/1782/2', // Basketball
    '8/1784/2', // Basketball
    '7/924/5', // Tennis
    '204/-1/5', // Tennis
    '210/-1/5', // Tennis
    '7/1324/5', // Tennis
    '310/-1/23', // Volleyball
    '309/-1/23', // Volleyball
    '246/-1/20', // Table Tennis
    '247/-1/20', // Table Tennis
    '460/-1/4', // Ice Hockey
    '446/-1/4', // Ice Hockey
    '8/1062/4', // Ice Hockey
    '8/1064/4', // Ice Hockey
  ],
};
