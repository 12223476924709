import axios from 'axios';
import { Core, Display } from '@nsftx/systems-sdk';
import config from './config';
import getQueryParam from './queryParam'

let settings;
export default class SystemsSDK {
  establishConnectionWithHub() {
    return new Promise((resolve, reject) => {
      this.sys.connect().catch(error => reject(new Error(error)));
      this.sys.on('connection', () => resolve());
    });
  }

  async getApplicationConfiguration() {
    const urlParams = new URLSearchParams(window.location.search);
    const appRunUuid = urlParams.get('appRunUuid') || '';

    const display = new Display(this.sys);
    const data = JSON.parse(await display.getApplication(appRunUuid));

    if (!data.config_url) throw new Error('Config not valid, config_url is empty.');

    return axios({
      method: 'GET',
      url: data.config_url,
      auth: {
        username: process.env.VUE_APP_SYSTEMS_USERNAME,
        password: process.env.VUE_APP_SYSTEMS_PASSWORD,
      }
    });
  }

  getLayout(layout = '') {
    const [layoutId = 5, layoutType = 5] = layout.split('-');

    return {
      id: config.layouts.id[layoutId],
      type: config.layouts.type[layoutType],
    };
  }

  getLogoSources(env, companyUuid, companyDisplayName) {
    if (!companyDisplayName) return { cms: '', assets: '' };

    return {
      cms: `${config.API.cms[env]}/${
        config.API.gravity.applicationKey[companyUuid] || companyDisplayName
      }/${companyDisplayName}_logo-images/live-visualization_logo.png`,

      assets: `${config.assetPath}/${companyDisplayName}/media/live-betting-display/${
        env === 'production' ? 'master' : env
      }/logo.png`,
    };
  }

  formatSettingsOutOfQueryParams() {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const env = queryParams.env || 'staging';
    const companyUuid = queryParams.companyUuid || 'b99752b3-443c-4c80-b559-945a95c4b805';

    return {
      app: {
        socketCompression: !queryParams.inspect,
        debug: !!queryParams.debug,
        env: queryParams.env || 'staging',
        language: (queryParams.lang || 'en').replace('_', '-'),
        theme: queryParams.theme || '',
        outcomeIndicator: !getQueryParam('disableOutcomeIndicator'),
        template: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),

        layout: this.getLayout(queryParams.tv),
        assets: this.getLogoSources(env, companyUuid, queryParams.logo),
      },
      company: {
        cpv: queryParams.cpv || '6f70074d-28f5-4941-89ba-2199d794cb9f',
        companyUuid,
        companyDisplayName: queryParams.logo || 'prvafirma',
      },
    };
  }

  get settings() {
    return settings;
  }

  async init() {
    let timeout;

    try {
      const timeoutPromise = new Promise((_, reject) => {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
          reject(new Error("We coudln't establish connection with Systems SDK. Application will use data from query parameters."));
        }, 5000);
      });

      const connectionPromise = (async () => {
        this.sys = new Core('LiveVisualizationV3');
        await this.establishConnectionWithHub();

        const { data: appCfg } = await this.getApplicationConfiguration();
        const device = await this.sys.deviceState();
        clearTimeout(timeout);

        const companyUuid = device.space.uuid;
        const env = appCfg.environment;
        const companyDisplayName = device.space.name.replace(/[\s,.]/g, '').toLowerCase();

        return {
          app: {
            socketCompression: true,
            debug: device.device.debug_mode,
            env,
            language: appCfg.app_config.language,
            theme: appCfg.app_config.theme,
            outcomeIndicator: appCfg.outcomeIndicator,
            template: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
            layout: this.getLayout(appCfg.app_config.layout),
            assets: this.getLogoSources(env, device.space.uuid, companyDisplayName),
          },
          company: {
            cpv: appCfg.cpv_uuids[0],
            uuid: companyUuid,
            companyDisplayName,
          },
        };
      })();

      settings = await Promise.race([connectionPromise, timeoutPromise]);
      return settings;
    } catch (error) {
      clearTimeout(timeout);
      settings = this.formatSettingsOutOfQueryParams();
      return settings;
    }
  }
}
