import Utils from '../services/utils';

export default class Category {
  constructor(options) {
    this.id = parseInt(options.idCategory, 10);
    this.active = !!options.categoryActive;
    this.name = options.categoryName;
    this.nameShort = options.categoryShortName;
    this.nameIso = options.categoryIsoName;
    this.position = Utils.setPosition(options.categoryPosition);
  }

  update(options) {
    this.active = !!options.categoryActive;
    this.name = options.categoryName;
    this.nameShort = options.categoryShortName;
    this.nameIso = options.categoryIsoName;
    this.position = Utils.setPosition(options.categoryPosition);
  }
}
