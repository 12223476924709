import {
  each,
  sortBy,
  map,
  find,
} from 'lodash';
import MatchOutcome from './match-outcome';

export default class MatchBet {
  constructor(options) {
    this.active = !!options.mbActive;
    this.id = options.idMb;
    this.idBet = options.idBet;
    this.changed = options.mbChanged;
    this.special = (options.mbSpecialValue !== '*' && options.mbSpecialValue.indexOf(':') === -1)
      ? options.mbSpecialValue : false;
    this.initialSpecial = (options.mbSpecialValue !== '*')
      ? options.mbSpecialValue : false;
    this.position = options.mbPosition;
    this.mostBalanced = options.mostBalanced;
    this.outcomes = sortBy(map(options.mbOutcomes, mbo => new MatchOutcome(mbo)), 'position');
  }

  setActiveState(state) {
    this.active = state;
  }

  updateFromSetOdds(options) {
    this.active = !!options.mbActive;
    this.changed = options.mbChanged;
    this.position = options.mbPosition;
    this.mostBalanced = options.mostBalanced;

    each(options.mbOutcomes, (mbo) => {
      const outcome = find(this.outcomes, o => o.id === mbo.idMbo);
      if (outcome) outcome.updateFromSetOdds(mbo);
    });
  }
}
