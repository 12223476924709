import { each, find } from 'lodash';
import Team from './match-team';
import MatchBet from './match-bet';
import MatchStatus from './match-status';
import ScoutEvent from './match-scout-event';
import Utils from '../services/utils';

export default class Match {
  constructor(options) {
    this.displayable = false;
    this.id = parseInt(options.idMatch, 10);
    this.idDisplay = parseInt(options.matchDisplayId, 10);
    this.idSport = parseInt(options.idSport, 10);
    this.idCategory = parseInt(options.idCategory, 10);
    this.idTournament = parseInt(options.idTournament, 10);
    this.active = options.active && options.matchActive;
    this.bettingStatus = options.bettingStatus;
    this.startAt = options.matchDateTime;
    this.hasStream = options.hasStream;
    this.hasScout = options.hasScout;
    this.position = Utils.setPosition(options.position);
    this.status = new MatchStatus(options.currentStatus);
    this.scoutEvents = [];
    this.mediaErrorMessage = '';

    // Set teams
    this.teams = [];
    for (let i = 1; i < 3; i += 1) {
      this.teams.push(new Team({
        id: options[`idTeam${i}`],
        name: options[`team${i}Name`],
        nameShort: options[`team${i}ShortName`],
        tournamentPrefix: options.tournamentPrefix,
      }));
    }

    // Set name
    this.name = `${this.teams[0].name} - ${this.teams[1].name}`;
    this.nameShort = `${this.teams[0].nameShort} - ${this.teams[1].nameShort}`;

    // Set bets
    this.bets = [];
    each(options.matchBets, (mb) => {
      this.bets.push(new MatchBet(mb));
    });
  }

  update(options) {
    this.active = options.active && options.matchActive;
    this.bettingStatus = options.bettingStatus;
    this.startAt = options.matchDateTime;
    this.hasStream = options.hasStream;
    this.hasScout = options.hasScout;
    this.position = Utils.setPosition(options.position);
  }

  updateBettingStatus(options) {
    this.bettingStatus = options.bettingStatus;
  }

  updateDisplayableState(displayable) {
    this.displayable = displayable;
  }

  // Set detailed info about match (matchSelection -> matchDetails)
  setTemplateInfo(info) {
    this.templateInfo = info;
  }

  updatePositionCategory(position) {
    this.positionCategory = position;
  }

  updatePositionTournament(position) {
    this.positionTournament = position;
  }

  updateFromSetOdds(options) {
    this.active = options.active;
    // this.bets.length = 0;
    // each(options.matchBets, (mb) => {
    //   this.bets.push(new MatchBet(mb));
    // });

    // Disable all bets
    each(this.bets, (bet) => {
      bet.setActiveState(false);
    });

    // Update new/old bets
    each(options.matchBets, (mb) => {
      const bet = find(this.bets, b => b.id === mb.idMb);
      if (bet) bet.updateFromSetOdds(mb);
      else this.bets.push(new MatchBet(mb));
    });
  }

  getTeam(idTeam) {
    return find(this.teams, team => team.id === idTeam);
  }

  updateMatchMediaState(message) {
    this.mediaErrorMessage = message.error;
    this.hasStream = Object.prototype.hasOwnProperty.call(message, 'hasStream')
      ? message.hasStream : this.hasStream;
    this.hasScout = Object.prototype.hasOwnProperty.call(message, 'hasScout')
      ? message.hasScout : this.hasScout;
  }

  addScoutEvent(message) {
    const event = new ScoutEvent(message);
    if (event.showEvent) {
      const isDuplicateEvent = find(this.scoutEvents, e => e.id === event.id);
      if (!isDuplicateEvent) {
        this.scoutEvents.push(event);
      }
    }
  }
}
