const isEventDisplayable = typeId => [
  164, 1014, // Newly added
  30, 40, 43, 45, 50, 60, 90, 124, 154, 161, 666, 1012, 1019,
  1024, 1025, 1033, 1035, 1037, 1041, 1046, 1049, 1085,
].indexOf(typeId) > -1;

export default class ScoutEvent {
  constructor(options) {
    this.id = options.idEvent;
    this.typeId = +options.eventTypeId;
    this.time = options.matchTime;
    this.title = options.translated
      ? options.eventTranslation : options.eventInfo;
    if (options.playerName) this.player = options.playerName;
    this.showEvent = isEventDisplayable(this.typeId);

    switch (this.typeId) {
      case 30:
        this.icon = 'live-1';
        break;
      case 40:
        this.icon = 'card icon-yellow';
        break;
      case 45:
      case 50:
        this.icon = 'card icon-red';
        break;
      case 60:
        this.icon = 'transfer';
        break;
      case 154:
        this.icon = 'corner';
        break;
      case 161:
        this.icon = 'penalty';
        break;
      default:
        this.icon = '';
    }
  }
}
