import Utils from '../services/utils';

export default class Sport {
  constructor(options) {
    this.id = parseInt(options.idSport, 10);
    this.active = !!options.sportActive;
    this.name = options.sportName;
    this.nameShort = options.sportShortName;
    this.position = Utils.setPosition(options.sportPosition);
    this.matchesCount = 0;
  }

  resetMatchesCount() {
    this.matchesCount = 0;
  }

  updateMatchesCount(count) {
    this.matchesCount += count;
  }

  updateFromUpdateSport(options) {
    this.active = !!options.sportActive;
    this.name = options.sportName;
    this.nameShort = options.sportShortName;
    this.position = Utils.setPosition(options.sportPosition);
  }
}
